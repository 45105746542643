import Modals from './modals'
// import Accordion from './accordion'
import Carousel from './carousel'
import '@/scss/base.scss'
import { FreeMode } from 'swiper/modules'

class APP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      console.log('[TGST] Base online')

      const modals = new Modals()
      // const accordion = new Accordion()

      this.initCarousels()
    })
  }

  initCarousels() {
    this.carousels = {
      featured: new Carousel('.swiper.is-featured', {
        effect: 'fade',
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: true,
        },
      }),
      lists: new Carousel('.swiper.is-list.is-default', {
        freeMode: true,
        edgeSwipeDetection: 'prevent',
        slidesPerView: 1.1,
        breakpoints: {
          768: {
            slidesPerView: 2.2,
            // pagination: false,
          },
          1080: { slidesPerView: 3.3 },
        },
      }),
      actors: new Carousel('.swiper.is-actors', {
        freeMode: true,
        edgeSwipeDetection: 'prevent',
        slidesPerView: 1.7,
        breakpoints: {
          768: {
            slidesPerView: 2.2,
            // pagination: false,
          },
          1080: { slidesPerView: 5.3 },
        },
      }),

      gallery: new Carousel('.swiper.is-gallery', {
        freeMode: true,
        edgeSwipeDetection: 'prevent',
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-fraction',
          type: 'fraction',
        },
      }),

      review: new Carousel('.swiper.is-review', {
        edgeSwipeDetection: 'prevent',
        slidesPerView: 'auto',
        centeredSlides: true,
      }),
    }
  }
}

export default new APP()
